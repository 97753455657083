<template>
  <div style="min-height: 750px;">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['my_shopcart'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row style="font-size: 16px;">
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
        <b-row>
          <b-col sm="12" lg="9">
          <template v-if="d_loading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </template>
          <template v-else>
            <b-card no-body v-for="(shop_item, shop_item_index) in d_shopOrderList" style="min-height: 500px;">
              <b-card-header header-bg-variant="white" class="p-1">
                 <b-row style="margin: 0px; background-color: #93ffe4; padding: 5px;">
                  <b-col sm="12" lg="4">
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['2'] && shop_item.shop_data.data['0']['2']['lang'] && shop_item.shop_data.data['0']['2']['lang'][StoreLang]">
                        <img :src="shop_item.shop_data.data['0']['2']['lang'][StoreLang]" style="width: 30px; margin-right: 3px;">
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['2'] && shop_item.shop_data.data['0']['2']['val']">
                        <img :src="shop_item.shop_data.data['0']['2']['val']" style="width: 30px; margin-right: 3px;">
                      </template>
                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['1'] && shop_item.shop_data.data['0']['1']['lang'] && shop_item.shop_data.data['0']['1']['lang'][StoreLang]">
                        <strong :title="shop_item.shop_data.data['0']['1']['lang'][StoreLang]">{{ shop_item.shop_data.data['0']['1']['lang'][StoreLang].length > 30 ? shop_item.shop_data.data['0']['1']['lang'][StoreLang].substring(0,15) + '...' : shop_item.shop_data.data['0']['1']['lang'][StoreLang] }}</strong>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['1'] && shop_item.shop_data.data['0']['1']['val']">
                        <strong :title="shop_item.shop_data.data['0']['1']['val']">{{ shop_item.shop_data.data['0']['1']['val'].length > 30 ? shop_item.shop_data.data['0']['1']['val'].substring(0,15) + '...' : shop_item.shop_data.data['0']['1']['val'] }}</strong>
                      </template>
                  </b-col>
                  <b-col sm="12" lg="3">
                    <i class="fa fa-envelope"></i>

                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['29'] && shop_item.shop_data.data['0']['29']['lang'] && shop_item.shop_data.data['0']['29']['lang'][StoreLang]">
                        <strong>{{ shop_item.shop_data.data['0']['29']['lang'][StoreLang] }}</strong>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['29'] && shop_item.shop_data.data['0']['29']['val']">
                        <strong>{{ shop_item.shop_data.data['0']['29']['val'] }}</strong>
                      </template>
                  </b-col>
                  <b-col sm="12" lg="3">
                    <i class="fa fa-mobile-phone"></i>

                      <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['30'] && shop_item.shop_data.data['0']['30']['lang'] && shop_item.shop_data.data['0']['30']['lang'][StoreLang]">
                        <strong>{{ shop_item.shop_data.data['0']['30']['lang'][StoreLang] }}</strong>
                      </template>
                      <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['30'] && shop_item.shop_data.data['0']['30']['val']">
                        <strong>{{ shop_item.shop_data.data['0']['30']['val'] }}</strong>
                      </template>
                  </b-col>
                </b-row>
              </b-card-header>
              <b-card-body>
                <template v-for="(bundle_item, bundle_item_index) in shop_item.bundle_list" v-if="f_searchUserShopBundle(bundle_item)">
                   <b-row @mouseover="d_hoverIndex = bundle_item_index" @mouseleave="d_hoverIndex = ''" :style="StoreDevice.isMobile ? 'cursor: pointer; border-bottom: solid 1px #dcdcdc; font-weight: 900; background: #f7e48f;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc; font-weight: 900; background: #f7e48f;'">
                    <b-col sm="12" lg="6">
                      <template v-if="bundle_item.user_shop_bundle_data.data && bundle_item.user_shop_bundle_data.data['0'] && bundle_item.user_shop_bundle_data.data['0']['1'] && bundle_item.user_shop_bundle_data.data['0']['1']['lang'] && bundle_item.user_shop_bundle_data.data['0']['1']['lang'][StoreLang]">
                        <span :style="d_hoverIndex === bundle_item_index ? ' color: rgb(252, 0, 153, 1);' : ''">{{ bundle_item.user_shop_bundle_data.data['0']['1']['lang'][StoreLang] }}</span>
                      </template>
                      <template v-else-if="bundle_item.user_shop_bundle_data.data && bundle_item.user_shop_bundle_data.data['0'] && bundle_item.user_shop_bundle_data.data['0']['1'] && bundle_item.user_shop_bundle_data.data['0']['1']['val']">
                        <span :style="d_hoverIndex === bundle_item_index ? ' color: rgb(252, 0, 153, 1);' : ''">{{ bundle_item.user_shop_bundle_data.data['0']['1']['val'] }}</span>
                      </template>
                    </b-col>
                    <b-col sm="12" lg="2">
                      {{ f_calculateProductTotalPrice(bundle_item.product_list) }}
                    </b-col>
                    <b-col sm="12" lg="2"></b-col>
                    <b-col sm="12" lg="2">
                      <b-dropdown variant="white" size="sm" style="width: 100%; float: left;">
                        <template slot="button-content">
                          {{ StoreLangTranslation.data['process'][StoreLang] }}
                        </template>
                        <b-dropdown-item @click="f_cancelUserShopcartBundle(bundle_item.user_shopcart_bundle_data)"><i class="fa fa-trash"></i> {{ StoreLangTranslation.data['cancel'][StoreLang] }} </b-dropdown-item>
                      </b-dropdown>
                    </b-col>
                  </b-row>
                  <template v-for="(product_item, product_item_index) in bundle_item.product_list" v-if="f_showCheckProduct(product_item.product)">
                    <b-row :style="StoreDevice.isMobile ? 'cursor: pointer; border-bottom: solid 1px #dcdcdc; margin-left: 10px;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc; margin-left: 10px;'">
                      <b-col sm="12" lg="8">
                        <i @click="f_openProductIngredients(product_item.product)" style="border-radius: 10px; padding-right: 5px; margin: 3px; padding-left: 5px; width: 20px; background-color: lightblue; text-align: center;">{{ (product_item_index + 1).toString() }}</i>
                        <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['1'] && product_item.product.data['0']['1']['lang'] && product_item.product.data['0']['1']['lang'][StoreLang]">
                          <span>{{ product_item.product.data['0']['1']['lang'][StoreLang] }}</span>
                        </template>
                        <template v-else-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['1'] && product_item.product.data['0']['1']['val']">
                          <span>{{ product_item.product.data['0']['1']['val'] }}</span>
                        </template>
                      </b-col>
                      <b-col sm="12" lg="2"> {{ f_calculateProductTotalPrice([product_item]) }} </b-col>
                      <b-col sm="12" lg="2"></b-col>
                    </b-row>
                  </template>
                </template>
              </b-card-body>
             </b-card>
          </template>
            <!-- 
              StoreShopCartList.data = [
                {
                  "product_list": [],
                  "user_shopcart_bundle_data": {},
                  "user_shop_bundle_data": {},
                }
              ]
             -->
          </b-col>
          <b-col sm="12" lg="3">
            <b-button v-if="StoreShopCartList.data.length > 0" variant="warning" style="width: 100%" @click="f_userShopCartPay()">
              {{ StoreLangTranslation.data['approve_shopcart'][StoreLang] }}
              <i class="fa fa-angle-right"></i>
            </b-button>
            <b-card>
              <b-card-header header-bg-variant="white">{{ StoreLangTranslation.data['order_brief'][StoreLang] }}</b-card-header>
              <b-row>
                <b-col sm="12" lg="5">
                  {{ StoreLangTranslation.data['product_count'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="1"> : </b-col>
                <b-col sm="12" lg="5">
                  {{ f_calculateShopcartBundleCount() }}
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12" lg="5">
                  {{ StoreLangTranslation.data['total'][StoreLang] }}
                </b-col>
                <b-col sm="12" lg="1"> : </b-col>
                <b-col sm="12" lg="5">
                   {{ f_calculateAllBundleTotalPrice() }}
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { data as system_menu_list } from '@/options/system_menu_list';
import store from '@/store';
import UserShopService from '@/services/user_shop';
import { data as usr_shp_bund } from '@/options/usr_shp_bund';
import { data as usr_shpcrt_bund } from '@/options/usr_shpcrt_bund';
import { data as usr_shpcrt_bund_pro } from '@/options/usr_shpcrt_bund_pro';
import { data as usr_shpcrt_bund_pro_pay } from '@/options/usr_shpcrt_bund_pro_pay';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { mapGetters } from 'vuex';
export default {
  name: 'user_shoppingcart',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreShopCartList: 'StoreShopCartList',
    StoreCurrencyType: 'StoreCurrencyType',
  }),
  components: {
    PageInfo,
  },
  props: {},
  data () {
    return {
      d_loading: false,
      d_selectedProductListForPayment: {
        'user_shopcart_bundle_client_id': '',
        'product_list': [],
        'show': false
      },
      d_selectedProductPaymentData: {
        'data': '',
        'show': false
      },
      d_selectedProductData: {
        'data': '',
        'show': false
      },
      d_paymentModal: {
        'data': '',
        'show': false
      },
      d_usrShpcrtBundWdm: usr_shpcrt_bund,
      d_usrShpcrtBundProWdm: usr_shpcrt_bund_pro,
      d_usrShpcrtBundProPayWdm: usr_shpcrt_bund_pro_pay,
      d_currencyType: '0', // 0 tl, 1 dollar
      d_user: '',
      d_hoverIndex: '',
      d_searchText: '',
      d_frontendClientID: '',
      d_frontendList: [],
      d_usrShpBundleList: [],
      d_usrShpBundWdm: usr_shp_bund,
      d_shopOrderList: []
    };
  },
  created: function () {
    this.d_user = JSON.parse(localStorage.getItem('user'));
    this.d_userShopClientID = this.$route.query.user_shop_client_id;
    this.d_shopOrderList = this.StoreShopCartList.data;
    this.f_userShopCartBundleList();
  },
  beforeMount () {},
  mounted: function () {
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_usrShpBundWdm);
    // console.log('this.StoreShopCartList ', this.StoreShopCartList);
  },
  methods: {
    f_showCheckProduct: function (product_data) {
      let show_product = true;
      try {
        if (product_data.data['0']['48']['val']['value'] === '0') {
          show_product = false;
        }
      } catch(err) {}
      return show_product;
    },
    f_calculateShopcartBundleCount: function () {
      let total_count = 0;
      for (let i in this.StoreShopCartList.data) {
        total_count += this.StoreShopCartList.data[i].bundle_list.length;
      }
      return total_count;
    },
    f_userShopCartBundleList: function () {
      this.d_loading = true;
      let query = 'user_client_id=' + this.d_user.username;
      let data = {};
      UserShopService.user_shopcart_bundle_list(query, data)
        .then(resp => {
          this.d_loading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_shopOrderList = resp.data.list;
            if (this.StoreShopCartList.status === 1) {
              this.$store.commit('MutationShopCartList', { 'status': 0, 'data': this.StoreShopCartList.data });
            } else {
              this.$store.commit('MutationShopCartList', { 'status': 1, 'data': this.StoreShopCartList.data });
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_getRoutePath: function (target_route_name_en) {
      let route_path = '';
      for (let i in system_menu_list) {
        if (system_menu_list[i].route_name.en == target_route_name_en) {
          route_path = system_menu_list[i].route_path[this.StoreLang];
        }
        if (!route_path) {
          if (system_menu_list[i].list && system_menu_list[i].list.length) {
            for (let k in system_menu_list[i].list) {
              if (system_menu_list[i].list[k].route_name.en == target_route_name_en) {
                route_path = system_menu_list[i].list[k].route_path[this.StoreLang];
                break;
              }
            }
          }
        }
        if (route_path) {
          break;
        }
      }
      return route_path;
    },
    f_newUserShopBundle: function () {
      this.$router.push({ path: this.f_getRoutePath('user_shop_bundle_record'), query: {} });
    },
    f_userShopCartPay: function () {
      this.$router.push({ path: this.f_getRoutePath('user_shoppingcart_payment'), query: {} });
    },
    f_goToUserShopBundleProductListPage: function (user_shop_bundle_client_id) {
      this.$router.push({ path: this.f_getRoutePath('user_shop_bundle_product_list'), query: {'user_shop_client_id': this.d_userShopClientID, 'user_shop_bundle_client_id': user_shop_bundle_client_id} });
    },
    f_editThisUserShopBundle: function (user_shop_bundle_client_id) {
      let route_path = this.f_getRoutePath('user_shop_bundle_record');
      // console.log('route_path : ', route_path);
      this.$router.push({ path: route_path, query: { 'user_shop_bundle_client_id': user_shop_bundle_client_id, 'user_shop_client_id': this.d_userShopClientID } });
    },
    f_searchUserShopBundle: function (usr_shp_bund) {
      let res = false;
      if (this.d_searchText) {
        if (usr_shp_bund.w_id.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
          res = true;
        }
      } else {
        res = true;
      }
      return res;
    },
    f_userShopBundleList: function () {
      let query = 'user_client_id=' + this.d_user.username;
      query += '&user_shop_client_id=' + this.d_userShopClientID;
      let data = {};
      UserShopService.user_shop_bundle_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.list) {
              this.d_usrShpBundleList = resp.data.list;
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_cancelUserShopcartBundle: function (user_shopcart_bundle_data) {
      let query = 'user_client_id=' + this.d_user.username;
      query += '&user_shopcart_bundle_client_id=' + user_shopcart_bundle_data.client_id;
      let data = {};
      UserShopService.user_shopcart_bundle_cancel(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.cancel_status === 'completed') {
              this.f_userShopCartBundleList();
              alert(this.StoreLangTranslation.data['cancel_completed'][this.StoreLang]);
            } else {
              alert(resp.data.status_code, ' ', resp.data.status_message);
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_calculateBundleTotalPrice: function (bundle_products) {
      let currency_text = this.StoreCurrencyType.label;
      let total_amount = 0;
      for (let product_index in bundle_products) {
        try {
          for (let currency_amount_index in bundle_products[product_index].data['0']['25'].list) {
            let currency_amount_item = bundle_products[product_index].data['0']['25'].list[currency_amount_index];
            try {
              if (currency_amount_item['26']['val']['value'] === this.StoreCurrencyType.value) {
                total_amount += parseFloat(currency_amount_item['27']['val']);
              }
            } catch(err) {}
          }
        } catch(err) {}
      }
      return total_amount + ' ' + currency_text;
    },
    f_userShopCartBundleOrderList: function () {
      let query = 'user_client_id=' + this.d_user.username;
      let data = {};
      UserShopService.user_shopcart_bundle_order_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_shopOrderList = resp.data.list;
            if (this.StoreShopCartOrderList.status === 1) {
              this.$store.commit('MutationShopCartOrderList', { 'status': 0, 'data': this.StoreShopCartOrderList.data });
            } else {
              this.$store.commit('MutationShopCartOrderList', { 'status': 1, 'data': this.StoreShopCartOrderList.data });
            }
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_userShopCartBundleActiveList: function () {
      let query = 'user_client_id=' + this.d_user.username;
      let data = {};
      UserShopService.user_shopcart_bundle_active_list(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_shopOrderList = resp.data.list;
          } else {
            alert(resp.data.status_code, ' ', resp.data.status_message);
          }
        });
    },
    f_getEligibleBundleProductPaymentStatus: function (pay_data) {
      let pay_last_status = '';
      let pay_option_list = [];
      try {
        pay_last_status = pay_data.data['0']['0']['val']['value'];
      } catch(err) {}
      for (let option_index in this.d_usrShpcrtBundProPayWdm['0']['parameters']['0']['options']) {
        if (pay_last_status !== this.d_usrShpcrtBundProPayWdm['0']['parameters']['0']['options'][option_index].value) {
          pay_option_list.push(this.d_usrShpcrtBundProPayWdm['0']['parameters']['0']['options'][option_index]);
        }
      }
      return pay_option_list;
    },
    f_getEligibleBundleProductStatus: function (product_data) {
      let product_last_status = '';
      let product_option_list = [];
      try {
        product_last_status = product_data.data['0']['0']['val']['value'];
      } catch(err) {}
      for (let option_index in this.d_usrShpcrtBundProWdm['0']['parameters']['0']['options']) {
        if (product_last_status !== this.d_usrShpcrtBundProWdm['0']['parameters']['0']['options'][option_index].value) {
          product_option_list.push(this.d_usrShpcrtBundProWdm['0']['parameters']['0']['options'][option_index]);
        }
      }
      return product_option_list;
    },
    f_getEligibleBundleStatus: function (shopcart_bundle_data) {
      let bundle_last_status = '';
      try {
        bundle_last_status = shopcart_bundle_data.data['0']['0']['val']['value'];
      } catch(err) {}
      let bundle_option_list = [];
      for (let option_index in this.d_usrShpcrtBundWdm['0']['parameters']['0']['options']) {
        if (bundle_last_status !== this.d_usrShpcrtBundWdm['0']['parameters']['0']['options'][option_index].value) {
          bundle_option_list.push(this.d_usrShpcrtBundWdm['0']['parameters']['0']['options'][option_index]);
        }
      }
      return bundle_option_list;
    },
    f_getOptionLabel: function (option_value, options) {
      // console.log('option_value : ', option_value);
      // console.log('options : ', options);
      let option_label = '';
      for (let i in options) {
        if (options[i].value === option_value) {
          option_label = options[i].translation[this.StoreLang];
          break;
        }
      }
      return option_label;
    },
    f_getRoutePath: function (target_route_name_en) {
      let route_path = '';
      for (let i in system_menu_list) {
        if (system_menu_list[i].route_name.en == target_route_name_en) {
          route_path = system_menu_list[i].route_path[this.StoreLang];
        }
        if (!route_path) {
          if (system_menu_list[i].list && system_menu_list[i].list.length) {
            for (let k in system_menu_list[i].list) {
              if (system_menu_list[i].list[k].route_name.en == target_route_name_en) {
                route_path = system_menu_list[i].list[k].route_path[this.StoreLang];
                break;
              }
            }
          }
        }
        if (route_path) {
          break;
        }
      }
      return route_path;
    },
    f_newUserShopBundle: function () {
      this.$router.push({ path: this.f_getRoutePath('user_shop_bundle_record'), query: {} });
    },
    f_goToUserShopBundleProductListPage: function (user_shop_bundle_client_id) {
      this.$router.push({ path: this.f_getRoutePath('user_shop_bundle_product_list'), query: {'user_shop_client_id': this.d_userShopClientID, 'user_shop_bundle_client_id': user_shop_bundle_client_id} });
    },
    f_editThisUserShopBundle: function (user_shop_bundle_client_id) {
      let route_path = this.f_getRoutePath('user_shop_bundle_record');
      // console.log('route_path : ', route_path);
      this.$router.push({ path: route_path, query: { 'user_shop_bundle_client_id': user_shop_bundle_client_id, 'user_shop_client_id': this.d_userShopClientID } });
    },
    f_openPaymentScreen: function (product_list, user_shopcart_bundle_client_id) {
      this.d_selectedProductListForPayment.product_list = product_list;
      this.d_selectedProductListForPayment.user_shopcart_bundle_client_id = user_shopcart_bundle_client_id;
      this.d_selectedProductListForPayment.show = true;
    },
    f_openProductIngredients: function (product_data) {
      this.d_selectedProductData.show = true;
      this.d_selectedProductData.data = product_data;
    },
    f_openProductPaymentIngredients: function (payment_data) {
      this.d_selectedProductPaymentData.show = true;
      this.d_selectedProductPaymentData.data = payment_data;
    },
    f_calculateAllBundleTotalPrice: function () {
      let currency_text = this.StoreCurrencyType.label;
      let total_amount = 0;
      for (let shop_index in this.StoreShopCartList.data) {
        for (let bundle_index in this.StoreShopCartList.data[shop_index].bundle_list) {
          for (let product_index in this.StoreShopCartList.data[shop_index].bundle_list[bundle_index].product_list) {
            try {
              for (let currency_amount_index in this.StoreShopCartList.data[shop_index].bundle_list[bundle_index].product_list[product_index].product.data['0']['25'].list) {
                let currency_amount_item = this.StoreShopCartList.data[shop_index].bundle_list[bundle_index].product_list[product_index].product.data['0']['25'].list[currency_amount_index];
                try {
                  if (currency_amount_item['26']['val']['value'] === this.StoreCurrencyType.value) {
                    total_amount += parseFloat(currency_amount_item['27']['val']);
                  }
                } catch(err) {console.log(err)}
              }
            } catch(err) {console.log(err)}
          }
        }
      }
      return total_amount + ' ' + currency_text;
    },
    f_calculateProductTotalPrice: function (bundle_products) {
      let currency_text = this.StoreCurrencyType.label;
      let total_amount = 0;
      for (let product_index in bundle_products) {
        try {
          for (let currency_amount_index in bundle_products[product_index].product.data['0']['25'].list) {
            let currency_amount_item = bundle_products[product_index].product.data['0']['25'].list[currency_amount_index];
            try {
              if (currency_amount_item['26']['val']['value'] === this.StoreCurrencyType.value) {
                total_amount += parseFloat(currency_amount_item['27']['val']);
              }
            } catch(err) {}
          }
        } catch(err) {}
      }
      return total_amount + ' ' + currency_text;
    },
  },
  watch: {}
};

</script>

